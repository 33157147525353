@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-primary {
  background-color: #00719c;
}

.background-secondary {
  background-color: #00415a;
}

.bg-light {
  background-color: #00b8ff;
}

.bg-navbar {
  background-color: #dde7ea;
}

.bg-dark {
  background-color: #00415a;
}

.text-primary {
  color: #00719c;
}

.text-secondary {
  color: #009bd6;
}

.text-light {
  color: #00b8ff;
}

.text-dark {
  color: #00415a;
}

.text-custom {
  color: #001f2b;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#gradient-box {
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}

@keyframes radiate-large {
  0% {
    box-shadow: 0 0 100px rgba(128, 0, 128, 0.5);
  }
  50% {
    box-shadow: 0 0 100px rgba(128, 0, 128, 1);
  }
  100% {
    box-shadow: 0 0 100px rgba(128, 0, 128, 0.5);
  }
}

.radiating-purple-border {
  animation: radiate-large 3s infinite;
}

@keyframes radiate-small {
  0% {
    box-shadow: 0 0 40px rgba(128, 0, 128, 0.5);
  }
  50% {
    box-shadow: 0 0 40px rgba(128, 0, 128, 1);
  }
  100% {
    box-shadow: 0 0 40px rgba(128, 0, 128, 0.5);
  }
}

.radiating-purple-border-small {
  animation: radiate-small 3s infinite;
}
